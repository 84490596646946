
import React, { useState } from 'react'
import './ChatUploadFile.css'
import pdfImg from '../../../../../assets/images/svgs/pdfImg.svg'
import { actionSendFile } from '../../../../../actions'

const ChatUploadFile = (props: any) => {

    const [caption, setCaption] = useState<string>('')

    const handleSendFile = () => {
        if(props.image) actionSendFile(props.image, 'image', caption, props.number, 1, props.close)
        else if(props.file) actionSendFile(props.file, 'document', caption, props.number, 1, props.close)
    }

    const showFile = () => {
        if(props.file) {
            return (
                <div className='chatUploadFile-container-file'>
                    <img 
                        className="chatUploadFile-container-file-image"
                        src={pdfImg} 
                        alt="pdf"
                    />
                    <b className='chatUploadFile-container-file-text large bold'>{props.file?.name}</b>
                    <b className='chatUploadFile-container-file-text regular medium'>{props.file?.type + ' - ' + (props.file?.size / 1024)?.toFixed(0) + ' KB'}</b>
                </div>
            );
        } else if (props.image) {
            return (
                <img 
                    className="chatUploadFile-container-image"
                    src={URL.createObjectURL(props.image)} 
                    alt="Selected Preview"
                />
            );
        }
    }

    const isDisabled = () => {
        return (props.number !== '5491136883757' && props.number !== '5491158668386');
    }

    return (
        <div className='chatUploadFile-container'>
            <div className='chatUploadFile-container-header'>
                <span 
                    className="material-symbols-rounded" 
                    style={{ color: 'var(--primart500)', cursor: 'pointer' }}
                    onClick={() => props.close()}
                >close</span>
                <div className='chatUploadFile-container-header-icons'>
                    <span className="material-symbols-rounded" style={{ color: 'var(--primart500)'}}>quick_reference_all</span>
                    <span className="material-symbols-rounded" style={{ color: 'var(--primart500)'}}>edit</span>
                    <span className="material-symbols-rounded" style={{ color: 'var(--primart500)'}}>notes</span>
                </div>
            </div>
            {showFile()}
            <div className='chatUploadFile-container-caption'>
                <input
                    className='chatUploadFile-container-caption-input'
                    placeholder='Agrega un mensaje'
                    value={caption}
                    onChange={(e: any) => setCaption(e.target.value)}
                />
            </div>
            <div className='chatUploadFile-container-footer'>
                <b className='chatUploadFile-container-footer-text'>{props.number}</b>
                <span 
                    className="material-symbols-rounded" 
                    onClick={() => isDisabled() ? {} : handleSendFile()} 
                    style={{ color: 'var(--primary500)', cursor: isDisabled() ? 'auto' : 'pointer', opacity: isDisabled() ? 0.4 : 1 }}
                >send</span>
            </div>
        </div>
    );
}

export default ChatUploadFile;