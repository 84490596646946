import axios from './instanceActions'
import { 
    netGetLabels,
    netGetChatsByLabel,
    netGetChats,
    netGetChatsFromUser,
    netChangeUserCategory,
    netReadUserMessages,
    netSendText,
    netSendTemplate,
    netSendFile
} from "../net/Connector";

export const actionGetLabels = (setLabels: any) => {
    axios.get(netGetLabels())
        .then((response: any) => {
            const res = [...response.data]
            const chats = {
                id: 0,
                label: "CHATS",
                icon: "chat"
            }
            res.unshift(chats)
            setLabels(res)
        })
        .catch((error) => {
        })
}

export const actionGetChatsByLabel = (setMessages: any, labelSelected: any) => {
    axios.get(labelSelected ? netGetChatsByLabel(labelSelected) : netGetChats())
        .then((response: any) => {
            setMessages(response.data)
        })
        .catch((error) => {
        })
}

export const actionGetChatsFromUser = (userNumber: any, setSelectedChat: any, setSelectedChatMessages: any) => {
    axios.get(netGetChatsFromUser(userNumber))
        .then((response: any) => {
            setSelectedChat(userNumber)
            setSelectedChatMessages(response.data)
        })
        .catch((error) => {
        })
}

export const actionReadUserMessages = (userNumber: any) => {
    axios({
        method: 'post',
        url: netReadUserMessages(userNumber),
    })
        .then((response: any) => {
        })
        .catch((error) => {
        })
}

export const actionChangeUserLabel = (userNumber: any, label: any) => {
    axios({
        method: 'post',
        url: netChangeUserCategory(userNumber, label),
    })
        .then((response: any) => {
        })
        .catch((error) => {
        })
}

export const actionSendText = (text: any, toNumber: any, fromNumber: any, setMessage: any) => {
    axios({
        method: 'post',
        url: netSendText(text, toNumber, fromNumber),
    })
        .then((response: any) => {
            setMessage('')
        })
        .catch((error) => {
            setMessage('')
        })
}

export const actionSendTemplate = (template: any, toNumber: any, fromNumber: any) => {
    axios({
        method: 'post',
        url: netSendTemplate(template, toNumber, fromNumber),
    })
        .then((response: any) => {
        })
        .catch((error) => {
        })
}

export const actionSendFile = (file: any, fileType: any, caption: any, toNumber: any, fromNumber: any, close: any) => {
    const formData = new FormData();
    formData.append("file", file);
    axios({
        method: 'post',
        url: netSendFile(fileType, caption, toNumber, fromNumber),
        data: formData
    })
        .then((response: any) => {
            close()
        })
        .catch((error) => {
            close()
        })
}