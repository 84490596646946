export const estadoPagoList = [
    {
        title: 'Pagada',
        text: 'Indica que el pago ha sido completamente saldado.',
        color: '#008744',
    },
    {
        title: 'Pendiente',
        text: 'Indica que el pago aún no ha sido saldado.',
        color: '#D12440',
    },
    {
        title: 'Pendiente de inicio',
        text: 'Indica que el pago fue refacturado pero aún no pasó a estado pendiente.',
        color: '#858585',
    },
    {
        title: 'A imputar',
        text: 'Indica que el pago fue recibido y aún no ha sido aplicado en el sistema.',
        color: '#E6540B',
    },
    {
        title: 'Débito pendiente',
        text: 'Indica que un pago adeudado aún no ha sido liquidado mediante débito automático.',
        color: '#A705A7',
    },
    {
        title: 'Débito pagado',
        text: 'Indica que un pago mediante débito automático fue liquidado correctamente.',
        color: '#008744',
    },
    {
        title: 'No informado',
        text: 'Indica que no se tiene información del estado de pago',
        color: '#666666',
    },
]