import React, { useEffect, useState } from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './PolizasDetails.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import Headers from '../../components/Headers/Headers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UserData from '../../components/UserDatailsComponents/UserData/UserData';
import PolizasData from '../../components/PolizasDetailsComponents/PolizasData/PolizasData';
import PolizasItem from '../../components/PolizasDetailsComponents/PolizasItem/PolizasItem';
import html2canvas from 'html2canvas';
import DetailAccions from '../../components/DetailAccions/DetailAccions';
import Notification from '../../components/Notification/Notification';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { 
    libreDeudaAction,
    actionDownloadPolicyMA,
    actionDownloadPolicySC,
    coberturaAction,
    actionGetCobranzaData,
    cuponPagoAction,
    tarjetaCirculacionAction,
    polizaAction
} from '../../actions';
import PolizasCobranza from '../../components/PolizasDetailsComponents/PolizasCobranza/PolizasCobranza';
import PolizasMensajes from '../../components/PolizasDetailsComponents/PolizasMensajes/PolizasMensajes';

const PolizasDetails = (props: any) => {

    const [headerListSelected, setHeaderSelectedList] = useState<string>('')
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [enableEdit, setEnabledEdit] = useState<boolean>(false);
    const [notification, setNotification] = useState({ success: false, text: '' });
    const [policyNumber, setPolicyNumber] = useState<any>(null);
    const [policyUrl, setPolicyUrl] = useState<any>(null);
    const [policyInsurer, setPolicyInsurer] = useState<any>(null);
    const [checkoutData, setCheckoutData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate();
    const { id }: any = useParams();

    const { hash }: any = useLocation();
    const location: any = useLocation();
    const openItem: boolean = location?.state?.openItem;
    const [cobranzaData, setCobranzaData] = useState<any>(null)

    useEffect(() => {
        actionGetCobranzaData(id, setCobranzaData, setLoading);
    }, [])

    const accionList = [
        {
            name: 'Editar teléfono',
            onClick: () => setEnabledEdit(true),
            show: props.userData?.privileges?.filter(function(e: any) { return e === 'users:edit' }).length > 0 && headerListSelected === 'Usuario'
        },
        {
            name: 'Descargar detalle',
            onClick: () => downloadReport(),
            show: true
        },
        {
            name: 'Descargar póliza',
            onClick: () => polizaAction(checkoutData?.id, setLoadingDownload, policyNumber),
            show: checkoutData?.id
        },
        {
            name: 'Descargar cupón de pago',
            onClick: () => cuponPagoAction(policyNumber, cobranzaData, setLoadingDownload),
            show: policyNumber && !loading
        },
        {
            name: 'Descargar tarjeta de circulación',
            onClick: () => tarjetaCirculacionAction(policyNumber, cobranzaData, setLoadingDownload),
            show: policyNumber && !loading
        },
        {
            name: 'Descargar libre de deuda',
            onClick: () => libreDeudaAction(id, setLoadingDownload),
            show: policyNumber
        },
        {
            name: 'Descargar certificado de cobertura',
            onClick: () => coberturaAction(id, setLoadingDownload),
            show: checkoutData?.config?.error?.includes('Ya existe una solicitud/póliza con este dominio')
        },
    ]

    const headerList = [
        {
            name: 'Detalle de cobertura',
            click: () => {
                setHeaderSelectedList('Detalle de cobertura')
                navigate('#Detalle de cobertura')
            },
            component: <PolizasData setCheckoutData={setCheckoutData} polizaId={id} setPolicyUrl={setPolicyUrl} setPolicyNumber={setPolicyNumber} setPolicyInsurer={setPolicyInsurer}/>
        },
        {
            name: 'Item',
            click: () => {
                setHeaderSelectedList('Item')
                navigate('#Item')
            },
            component: <PolizasItem polizaId={id} openItem={openItem}/>
        },
        {
            name: 'Usuario',
            click: () => {
                setHeaderSelectedList('Usuario')
                navigate('#Usuario')
            },
            component: <UserData userId={id} from='poliza' enableEdit={enableEdit} setEnabledEdit={setEnabledEdit} setNotification={setNotification}/>
        },
        {
            name: 'Cobranza',
            click: () => {
                setHeaderSelectedList('Cobranza')
                navigate('#Cobranza')
            },
            component: <PolizasCobranza polizaId={id} policyNumber={policyNumber}/>,
            disabled: cobranzaData?.length == 0
        }
/*         {
            name: 'Mensajes',
            click: () => {
                setHeaderSelectedList('Mensajes')
                navigate('#Mensajes')
            },
            component: <PolizasMensajes polizaId={id} policyNumber={policyNumber}/>
        } */
    ]

    useEffect(() => {
        setHeaderSelectedList(hash.substring(1).replace(/%20/g, " "))
    }, [hash])

    const downloadPolicy = () => {
        if(policyInsurer === 17) {
            actionDownloadPolicyMA(policyNumber, setLoadingDownload)
        } else {
            actionDownloadPolicySC(policyNumber, setLoadingDownload)
        }
    }

    const downloadReport = async () => {
        setLoadingDownload(true);
        
        const generatePDF = (canvasList: any) => {
            const pdf = new jsPDF('l', 'px', [canvasList[0]?.height, canvasList[0]?.width]);
    
            canvasList.forEach((canvas: any, index: any) => {
                if (index > 0) {
                    pdf.addPage([canvas.height, canvas.width]);
                }
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, canvas.width, canvas.height, '', 'FAST');
            });
    
            pdf.save('Reporte.pdf');
        };
    
        const canvasList: any = [];
    
        for (const header of headerList) {
            navigate(`#${header.name}`, { state: { openItem: true } });
            await new Promise((resolve) => {
                setTimeout(resolve, 4000); // Wait for component to render (adjust timing as needed)
            });
            const componentContainer = document.getElementById('Poliza_Id');
            if (componentContainer) {
                const canvas = await html2canvas(componentContainer, { scrollY: -window.scrollY });
                canvasList.push(canvas);
            }
        }
    
        generatePDF(canvasList);
        setLoadingDownload(false);
    };
    
    return (
        <div className='polizasDetails-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='polizasDetails-container-main'>
                <div className='polizasDetails-container-main-hedear'>
                    <div className='polizasDetails-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                        mini
                    />
                </div>
                <div className='polizasDetails-container-main-body'>
                    <div className='polizasDetails-container-main-body-wrapp'>
                        <div className='polizasDetails-container-main-body-top'>
                            <div className='polizasDetails-container-main-body-top-text'>
                                <h2 className='polizasDetails-container-main-body-top-text-title bold'>Ver cobertura</h2>
                                <div className='polizasDetails-container-main-body-top-text-volver' onClick={() => navigate('/insurance_policies')}>
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.70711 0.292893C8.09763 0.683418 8.09763 1.31658 7.70711 1.70711L2.41421 7L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976318 7.31658 -0.0976317 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976312 7.31658 -0.0976311 7.70711 0.292893Z" fill="var(--primary500)"/>
                                    </svg>
                                    <p className='polizasDetails-container-main-body-top-text-volver-text bold small'>&nbsp;&nbsp;Volver a Coberturas</p>
                                </div>
                            </div>
                            <DetailAccions accionList={accionList} loadingDownload={loadingDownload}/>
                            <Notification
                                notification={notification}
                                setNotification={setNotification}
                            />
                        </div>
                        <div className='polizasDetails-container-main-body-content' id='Poliza_Id'>
                            <Headers 
                                list={headerList}
                                selected={headerListSelected}
                            />
                            {headerList?.filter((a: any) => a.name === headerListSelected)[0]?.component}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PolizasDetails;