export const getBasic = () => 'Basic V2VDb3ZlcjpGYWN1SXNHYXlzaGFu';

//LOGIN

export const loginUserAuth = () => '/oauth/token';

export const netLoginProcess = () => '/v2/login_process';

export const netLoginProcessId = (id: any) => '/v2/login_process/' + id;

export const getIdRecoveryPassword = () => '/public/user/reset_password';

export const postSuccessCodeRecoveryEmail = (id: string) => `/public/user/reset_password/${id}/email`;

export const postSuccessNewPassword = (id: string) => `/public/user/reset_password/${id}/password`;

//GENERAL

export const netUserMine = () => '/public/user/mine';

export const netSections = () => '/sections'

// DASHBOARD

export const netDashboard = (tag: string) => '/v2/dashboard?tags=' + tag

export const netDashboardList = () => '/v2/reports/_search'

export const netDashboardTrafico = (selectedFilter: any) => '/v2/dashboard/trafico?daysFilter=' + selectedFilter;

export const netDashboardDetalle = (selectedFilter: any, item: any) => '/v2/dashboard/detalle?daysFilter=' + selectedFilter + '&item=' + item;

export const netDashboardCompras = (selectedFilter: any) => '/v2/dashboard/compras?daysFilter=' + selectedFilter;

// TABLE

export const netTableHeader = (type: string) => '/sections/' + type

export const netTableSearch = (type: string) => '/' + type + '/_search'

export const netTableDownload = () => '/admin_report'

export const netTableDownloadCheck = (id: string) => '/admin_report/' + id

export const netCreateItem = (url: string) => url;

export const netDeleteTableRow = (url: string) => url;

export const netReadContact = (contactId: any) => '/generic_forms/read/' + contactId;

export const netDownloadTxt = () => '/broker/getNomina'; //PARDO

export const netUploadFile = () => '/archivos_automation/upload';

export const netGetFiles = () => '/archivos_automation/files';

// USER DATA

export const getIncompletedInsuredUser = (userId: any) => '/public/product_insured_user/incompleted_products?userId=' + userId;

export const netUserClientData = (userId: any) => '/v2/user/' + userId

export const netUserPolizasData = (userId: any) => '/insurance_policy/my_policys/' + userId

export const netUserCotizacionesData = (userId: any) => '/offers/' + userId

export const netUserClaimsData = (userId: any) => '/public/report/' + userId

export const getCivilTypes = () => '/public/user/civil';

export const getSexTypes = () => '/public/user/sexo';

export const getUserPlacesById = (id: number) => '/place?parent=' + id

export const getPlaceById = (id: number) => '/place/' + id

export const getStatesCon = () => '/public/user/provincias_table_orbis';

export const getLocByIdCon = (locId: any) => '/public/user/localidad_id_table_orbis?locId=' + locId;

export const getPlaces = () => '/place'

export const editUserById = (id: any) => '/public/user/fast_edit/' + id

export const netGetLocalidadText = (id: any) => '/v3/locations/' + id

export const getPardoData = (type: string) => '/v2/user/pardo_data/' + type //PARDO

export const getPardoLocalidades = (provinciaId: string) => '/v2/user/pardo_data_localidades/' + provinciaId //PARDO

export const getPardoCodigosPostales = (localidadId: string) => '/v2/user/pardo_data_codigos_postales/' + localidadId //PARDO

export const netCarfacilAvtividades = () => '/public/user/mercantil/activities' //CARFACIL

// POLIZA DATA

export const netPolizaData = (polizaId: any) => '/insurance_policy/' + polizaId

export const netUserDataFromPoliza = (polizaId: any) => '/admin_poliza/user/' + polizaId

export const netCheckoutData = (id: any) => '/checkout_process/' + id

export const netChangeStatus = () => '/admin/product_insured/status';

export const netCheckOutRequerements = (id: string) => '/checkout_process/' + id + '/customer_product_requeriments'

export const netDownloadPolicyMA = (id: any) => '/v2/policy/mercantil/' + id;

export const netDownloadPolicySC = (id: any) => '/v2/policy/sancristobal/' + id;

export const netGetCobranzaData = (id: any) => '/v2/policy/payment/' + id

export const cuponPagoNet = () => '/admin_poliza/payment_cupon'

export const polizaNet = (checkoutId: any) => '/v2/policy/policy_pdf/' + checkoutId;

export const tarjetaCirculacionNet = () => '/admin_poliza/tarjeta_circulacion'

// CLAIMS DATA

export const netClaimsData = (claimId: any) => '/admin_claims/' + claimId

export const netUserDataFromClaim = (claimId: any) => '/admin_claims/user/' + claimId

export const netPolizaDataFromClaim = (claimId: any) => '/admin_claims/insurance_policy/' + claimId

export const netCommentClaim = () => '/public/comment'

export const netStatusChangeClaim = () => '/admin/answer/report/status'

// VENDEDOR DATA

export const netGetSucursalList = () => '/v2/user/available_branches'

//COTIZACION DATA

export const netSaveCotizacion = (categoryId: any, quoteId: any) => '/v2/' + categoryId +'/quote/' + quoteId + '/save';

export const netGetOffer = (categoryId: Number, offer: String) => '/' + categoryId + '/offers/' + offer;

export const netGetQuoteQuestions = (categoryId: Number, quote: String) => '/v2/' + categoryId + '/quote/' + quote

export const netCreateOffer = (categoriaId: number, quoteId: string) => '/' + categoriaId + '/offers/' + quoteId;

//CHATS

export const netGetLabels = () => '/v2/chats/labels';

export const netGetChatsByLabel = (labelSelected: any) => '/v2/chats/chats_label/' + labelSelected;

export const netGetChats = () => '/v2/chats/chats_label';

export const netGetChatsFromUser = (userNumber: any) => '/v2/chats/' + userNumber;

export const netChangeUserCategory = (userNumber: any, label: any) => '/v2/chats/label/' + userNumber + '/' + label;

export const netReadUserMessages = (userNumber: any) => '/v2/chats/message_read/' + userNumber;

export const netSendText = (text: any, toNumber: any, fromNumber: any) => '/send/text?text=' + text + '&to_number=' + toNumber + '&from_number=' + fromNumber;

export const netSendTemplate = (template: any, toNumber: any, fromNumber: any) => '/send/template?template=' + template + '&to_number=' + toNumber + '&from_number=' + fromNumber;

export const netSendFile = (fileType: any, caption: any, toNumber: any, fromNumber: any) => '/send/file?file_type=' + fileType + '&caption=' + caption + '&to_number=' + toNumber + '&from_number=' + fromNumber;