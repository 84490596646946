import axios from "axios";
import Cookies from 'universal-cookie';

const instance = axios.create({
	baseURL : process.env.REACT_APP_API
});

instance.interceptors.request.use(
	async (config: any) => {
        let cookies = new Cookies();
		const token = cookies.get('userAdminAccessToken')
		const countryId = process.env.REACT_APP_COUNTRY
		const channelId = process.env.REACT_APP_ID
		if (token) {
			config.headers['Authorization'] = token
		}
		if (countryId) {
			config.headers['CountryId'] = countryId
		}
		if (channelId) {
			config.headers['ChannelId'] = channelId
		}
		return config
	}
)

instance.interceptors.response.use( 
	function (response: any) {
		return Promise.resolve(response)
	},
	function (error: any) {
		if (error.response && error.response.status === 401) {
			let cookies = new Cookies();
			cookies.remove('userAdminAccessToken');
			cookies.remove('rememberAccount');
			
			window.location.href = '/'; 
		}
        return Promise.reject(error);
    }
)

export default instance;