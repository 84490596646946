import axios from './instanceActions'
import axiosOriginal from 'axios';
import { 
    netTableDownload,
    netTableDownloadCheck,
    netTableHeader,
    netTableSearch,
    netCreateItem,
    netDeleteTableRow,
    netReadContact,
    netDownloadTxt,
    netUploadFile,
    netGetFiles
} from "../net/Connector";
import Cookies from 'universal-cookie';

export const actionsTableHeader = (setChartData, setFormData, setLoadingChart, type) => {
    setLoadingChart(true)
    axios.get(netTableHeader(type)) 
        .then((response) => {
            setChartData(response.data.chart)
            setFormData(response.data.form)
            setLoadingChart(false)
        })
        .catch((error) => {
            setLoadingChart(false)
        })
};

let cancelTokenSource = axiosOriginal.CancelToken.source();
let lastRequestId = 0; // Contador para identificar la última solicitud

export const actionsTableDataRows = (setChartDataRows, data, setLoadingChart, search) => {
    // Cancel previous request if still pending
    if (cancelTokenSource) {
        cancelTokenSource.cancel("Request canceled due to new request.");
    }

    // Create a new cancel token
    cancelTokenSource = axiosOriginal.CancelToken.source();

    const requestId = ++lastRequestId; // Asignamos un ID único a la solicitud
    setLoadingChart(true);

    let cookies = new Cookies();

    axiosOriginal({
        method: "post",
        url: process.env.REACT_APP_API + netTableSearch(search),
        data,
        cancelToken: cancelTokenSource.token,
        headers: {
            Authorization: cookies.get("userAdminAccessToken"),
            CountryId: process.env.REACT_APP_COUNTRY,
            ChannelId: process.env.REACT_APP_ID,
        },
    })
        .then((responseB) => {
            if (requestId === lastRequestId) {
                // Solo la última solicitud puede actualizar el estado
                setChartDataRows(responseB.data);
                setLoadingChart(false);
            }
        })
        .catch((error) => {
            if (axiosOriginal.isCancel(error)) {
                console.log("Previous request canceled");
            } else if (requestId === lastRequestId) {
                setLoadingChart(false);
            }
        });
};

export const actionsTableDownload = (dataFilter, dataSort, setDownloading, type) => {
    const data = { name: type }
    if(dataFilter) {
        data['filter'] = dataFilter;
        data['sort'] = dataSort;
    }
    setDownloading(true)
    axios({
        method: 'post',
        url: netTableDownload(),
        data
    })
        .then((response) => {
            const downloadStatus = () => {
                axios(netTableDownloadCheck(response.data.id))
                    .then(responseStat => {
                        if(responseStat.data.status === "COMPLETED") {
                            window.open(responseStat.data.url);
                            clearInterval(refreshIntervalId);
                            setDownloading(false)
                        }
                    })
                    .catch(error => {
                        clearInterval(refreshIntervalId);
                        setDownloading(false)
                    })
            }
            var refreshIntervalId = setInterval(downloadStatus, 2000);
        })
        .catch((error) => {
            setDownloading(false)
        })
};

export const actionsCreateItem = (data, url, setLoading, setSuccess, setError) => {
    setLoading(true)
    axios({
        method: 'post',
        url: netCreateItem(url),
        data
    })
        .then((response) => {
            setSuccess(true)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
            if(error?.response?.data?.errors) setError(error?.response?.data?.errors[0])
        })
}

export const actionsDeleteTableRow = (userId, url, setLoading, setSuccess) => {
    setLoading(true)
    axios({
        method: 'delete',
        url: netDeleteTableRow(url),
        params: { userId: userId }
    })
        .then((response) => {
            setSuccess(true)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
}

export const actionsReadContact = (contactId) => {
    axios({
        method: 'post',
        url: netReadContact(contactId)
    })
        .then((response) => {
            window.location.reload();
        })
        .catch((error) => {
        })
}

export const actionsDownloadTxt = (dataSend, date, setLoading) => {
    setLoading(true);
    axios({
        method: 'post',
        url: netDownloadTxt(),
        data: dataSend,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => {
            const txtContent = response.data;
            const blob = new Blob([txtContent], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `SALUD001_${date}.txt`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        })
}

export const actionsUploadFile = (file, type, prefixFile, setUploading, setUploadSuccess) => {
    setUploading(true)
    const formData = new FormData();
    formData.append('excelFile', file);
    formData.append('prefixFile', prefixFile);
    axios({
        method: 'post',
        url: netUploadFile(),
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((response) => {
            setUploading(false);
            setUploadSuccess({ success: true, text: 'Exito' });
        })
        .catch((error) => {
            setUploading(false);
            setUploadSuccess({ success: false, text: 'Error: ' + error?.response?.data?.message });
        })
}

export const actionsGetFiles = (setList) => {
    axios.get(netGetFiles())
        .then((response) => {
            setList(response.data)
        })
        .catch((error) => {
        })
}