
import React, { useEffect, useState } from 'react'
import './ChatBubble.css'
import Moment from 'moment/moment'

const ChatBubble = (props: any) => {

    const [messageTemplate, setMessageTemplate] = useState<any>(null);

    useEffect(() => {
        if(props.messageType === "TEMPLATE" && props.messageBody) {
            const json = JSON.parse(props.messageBody)
            let text = ''
            json?.components?.map((a: any) => {
                if (a?.type === "BODY") text = text + a?.text
                else if (a?.type === "BUTTONS") text = text + " Botón: " + a?.buttons[0]?.text
            })
            setMessageTemplate(text)
        }
    }, [props.messageType, props.messageBody])

    const divideText = (text: string, searchText: string) => {
        const words: any = text?.split(" ");
        const highlight = searchText?.length >= 2 ? searchText : null;
        return words?.map((word: any, index: any) => (
            word?.toLowerCase()?.includes(highlight?.toLowerCase()) ? (
                <div className="chatBubble-container-mensaje-word">
                    <b
                        key={index}
                        className="chatBubble-container-mensaje-word-text regular medium"
                        style={{ 
                            backgroundColor: props.occurrenceSelected ? 'var(--gray700)' : 'var(--successPrimary)',
                            color: 'var(--white)'
                        }}
                    >
                        {word}
                    </b>
                    &nbsp;
                </div>
            ) : (
                <div className="chatBubble-container-mensaje-word">
                    <b
                        key={index}
                        className="chatBubble-container-mensaje-word-text regular medium"
                        style={{ color: props.reciever ? 'var(--gray700)' : 'var(--white)' }}
                    >
                        {word}
                    </b>
                    &nbsp;
                </div>
            )
        ));
    };

    const generateBubbleContent = () => {
        switch(props.messageType) {
            case "AUDIO":
                return (
                    <audio controls>
                        <source src={props.mensaje} type="audio/ogg" />
                    </audio>
                )
            case "IMAGE":
                return (
                    <div 
                        className='chatBubble-container-file'
                        style={{ backgroundColor: props.reciever ? 'var(--gray50)' : 'var(--primary500)' }}
                    >
                        <img
                            className='chatBubble-container-file-img'
                            src={props.mensaje}
                            alt="Displayed from URL"
                        />
                        {props.caption && 
                            <b 
                                className='chatBubble-container-file-caption regular medium'
                                style={{ color: props.reciever ? 'var(--gray700)' : 'var(--white)' }}
                            >{props.caption}</b>}
                    </div>
                )
            case "TEMPLATE":
                return (
                    <div 
                        className='chatBubble-container-mensaje'
                        style={{ backgroundColor: props.reciever ? 'var(--gray50)' : 'var(--primary500)' }}
                    >
                        <b className="chatBubble-container-mensaje-word regular medium">{props.mensaje}</b>
                        <span 
                            className='chatBubble-container-mensaje-toolTip'
                            style={{
                                right: props.reciever ? 'unset' : '100%',
                                left: props.reciever ? '100%' : 'unset'
                            }}
                        >
                            {props.messageBody}
                        </span>
                    </div>
                )
            default:
                return (
                    <div 
                        className='chatBubble-container-mensaje'
                        style={{ backgroundColor: props.reciever ? 'var(--gray50)' : 'var(--primary500)' }}
                    >
                        {divideText(props.mensaje, props.searchText)}
                    </div>
                )
        }

    }

    return (
        <div 
            className='chatBubble-container'
            style={{
                marginLeft: props.reciever ? 10 : 'auto',
                marginRight: props.reciever ? 0 : 10,
                alignItems: props.reciever ? 'flex-start' : 'flex-end'
            }}
        >
            {generateBubbleContent()}
            <div className='chatBubble-container-time'>
                <b className='chatBubble-container-time-fecha light small'>{Moment(props.fecha).format('HH:mm A')}</b>
                <span 
                    className="material-symbols-rounded" 
                    style={{ 
                        fontSize: 16, 
                        color: props.status === 'READ' ? 'var(--primary500)' : props.status === 'FAILED' ? 'var(--errorPrimary)' : 'var(--gray300)' 
                    }}
                >
                    {props.status === 'SENT' ? 'check' : props.status === 'FAILED' ? 'error' : 'done_all'}
                </span>
                {!props.reciever && <b className='chatBubble-container-time-fecha light small'>{props.senderType}</b>}
                {props.errorMessage && 
                    <span 
                        className='chatBubble-container-time-toolTip'
                        style={{
                            right: props.reciever ? 'unset' : '100%',
                            left: props.reciever ? '100%' : 'unset'
                        }}
                    >
                        {props.errorMessage}
                    </span>
                }
            </div>
        </div>
    );
}

export default ChatBubble;