
import React, { useEffect, useState } from 'react'
import './ChatSender.css'
import { actionSendText } from '../../../../../actions';

const ChatSender = (props: any) => {

    const [message, setMessage] = useState<string>('')
    const [menu, setMenu] = useState<boolean>(false);

    const menuList = [
        {
            id: 1,
            label: 'Photos/Imagenes',
            icon: 'image',
            action: () => {
                setMenu(false)
                document.getElementById('image-upload')?.click()
            }
        },
        {
            id: 2,
            label: 'Archivos',
            icon: 'description',
            action: () => {
                setMenu(false)
                document.getElementById('file-upload')?.click()
            }
        }
    ]

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files[0]) {
            const imageFile = files[0];
            props.setImage(imageFile);
            props.setUploadingFile(true)
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files[0]) {
            const file = files[0];
            props.setFile(file);
            props.setUploadingFile(true)
        }
    };

    const handleMessageSend = () => {
        actionSendText(message, props.number, 1, setMessage)
    }

    const isDisabled = () => {
        return (props.number !== '5491136883757' && props.number !== '5491158668386');
    }

    return (
        <div className='chatSender-container'>
            <div className='chatSender-container-wrap'>
                <div className='chatSender-container-wrap-menu'>
                    {menu &&
                        <div className='chatSender-container-wrap-menu-list'>
                            <div className='chatSender-container-wrap-menu-list-wrap'>
                                {menuList?.map((menuItem: any, index: any) => (
                                    <div 
                                        key={index}
                                        className='chatSender-container-wrap-menu-list-wrap-item'
                                        onClick={menuItem?.action}
                                    >
                                        <div className='chatSender-container-wrap-menu-list-wrap-item-wrap'>
                                            <span className="material-symbols-rounded" style={{ color: 'var(--gray700)' }}>{menuItem?.icon}</span>
                                            <b className='chatSender-container-wrap-menu-list-wrap-item-wrap-text regular medium'>{menuItem?.label}</b>
                                        </div>        
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    <div 
                        className='chatSender-container-wrap-menu-button'
                        style={{transform: !menu ? 'rotate(0deg)' : 'rotate(-45deg)'}}
                        onClick={() => {
                            setMenu(!menu)
                            props.setImage(null);
                            props.setFile(null);
                        }}
                    >   
                        <h4 className='chatSender-container-wrap-menu-button-text regular'>+</h4>
                    </div>
                </div>
                <input
                    className='chatSender-container-wrap-input'
                    placeholder='Escribe un mensaje'
                    value={message}
                    onChange={(e: any) => setMessage(e.target.value)}
                />
                <span 
                    className="material-symbols-rounded" 
                    onClick={() => isDisabled() ? {} : handleMessageSend()} 
                    style={{ color: 'var(--primary500)', cursor: isDisabled() ? 'auto' : 'pointer', opacity: isDisabled() ? 0.4 : 1 }}
                >send</span>
            </div>
            <input
                id="image-upload"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
            <input
                id="file-upload"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileUpload}
            />
        </div>
    );
}

export default ChatSender;