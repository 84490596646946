import React, { useEffect, useState } from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './Mensajes.css'
import TableHeader from '../../components/TableHeader/TableHeader';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Label from './Label/Label';
import Chats from './Chats/Chats';
import { actionGetChatsByLabel, actionGetLabels } from '../../actions';
import useWebSocket from '../../util/useWebSocket';

const Mensajes = (props: any) => {
    const { hash } = useLocation();
    const location = useLocation();
    const navigate = useNavigate();
    const [labelSelected, setLabelSelected] = useState<any>(null);
    const [messages, setMessages] = useState<any>(null);
    const [labels, setLabels] = useState<any>(null);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        handleUrlParams()
    }, [searchParams])

    useEffect(() => {
        actionGetLabels(setLabels)
    }, [])

    const handleUrlParams = () => {
        const label: any = searchParams.get("label")
        if(label !== labelSelected) {
            setLabelSelected(label)
            setMessages(null)
            //actionGetChatsByLabel(setMessages, label === "CHATS" ? null : label)
        } else if(!label) {
            navigate('?label=CHATS')
        }
    }

    return (
        <div className='mensajes-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='mensajes-container-main'>
                <TableHeader countryId={props.userData?.countryId} optionList={props.userList} userData={props.userData}/>
                <div className='mensajes-container-main-content'>
                    <h2 className='mensajes-container-main-content-title'>{props.principalList?.filter((a: any) => a.path === location?.pathname)[0].name}</h2>
                    <div className='mensajes-container-main-content-section'>
                        <div className='mensajes-container-main-content-section-tabList'>
                            {labels?.map((label: any) => (
                                <Label
                                    icon={label?.icon}
                                    label={label?.label}
                                    selectLabel={() => navigate('?label=' + label?.label)}
                                    labelSelected={label?.label === labelSelected}
                                />
                            ))}
                        </div>
                        <Chats label={labelSelected} messages={messages} labels={labels}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mensajes;